<template>
  <div class="common withdraw">
    <h2 class="page-title">取款</h2>

    <div class="page-content auth-div" v-if="vfy1 || vfy2">
      <div class="auth-msg">
        提示：为保障您的资金安全，提现前请先完成以下资料认证！
      </div>
      <div class="img-box">
        <img
          src="@/assets/images/funds/vfy1.png"
          alt=""
          @click="goBind(1)"
          v-if="vfy1"
        />
        <img
          src="@/assets/images/funds/vfy2.png"
          alt=""
          @click="goBind(2)"
          v-if="vfy2"
        />
      </div>
    </div>
    <div class="page-content auth-msg" v-if="isAuthMTMsg">
      {{ isAuthMTMsg }}
      <a @click="handleGoInfo">查看详情</a>
    </div>
    <div class="page-content">
      <div class="withdraw-div form form-input" v-if="isAuthentication">
        <div class="balance-wap">
          <h1>{{ balance }}</h1>
          <span>余额(美元)</span>
        </div>
        <div class="input-div balance-pc">
          <label class="form-label">可提现金额</label>
          <div class="input">
            <div class="input-content">
              <input
                :value="balance"
                type="number"
                readonly
                placeholder="可提现金额"
              />
              <i class="right-text">美元</i>
            </div>
          </div>
        </div>
        <div class="input-div">
          <label class="form-label">提现金额</label>
          <div class="input">
            <div class="input-content">
              <input
                v-model="withdrawInfo.mjMoney"
                type="number"
                form="form"
                v-checkParam="rules.money"
                @blur="handleMoney"
                placeholder="请输入提款金额"
              />
              <i class="right-text">美元</i>
            </div>
          </div>
        </div>
        <div class="input-div">
          <label class="form-label">换算人民币</label>
          <div class="input">
            <div class="input-content">
              <input
                v-model="outRmb"
                type="number"
                readonly
                placeholder="换算人民币"
              />
              <i class="right-text">元</i>
            </div>
          </div>
        </div>
        <div class="input-div">
          <label class="form-label">取款方式</label>
          <div class="input">
            <el-select
              class="select-pc"
              v-model="withdrawInfo.receiptMode"
              placeholder="请选择取款方式"
              @change="onCheckReceiptMode"
              v-if="!isWap"
            >
              <el-option
                v-for="item in receiptModeList"
                :key="item.val"
                :label="item.name"
                :value="item.val"
              >
              </el-option>
            </el-select>
            <div class="input-content select-wap" v-if="isWap">
              <input
                v-model="receiptModeText"
                readonly
                class="receiptMode"
                type="text"
                @click="handleShowReceiptMode"
                placeholder="请选择取款方式"
              />
              <i class="arrow"></i>
            </div>
          </div>
        </div>
        <div class="input-div" v-if="withdrawInfo.receiptMode == 6">
          <label class="form-label">钱包编码</label>
          <div class="input">
            <div class="input-content">
              <input
                v-model="withdrawInfo.walletcode"
                type="text"
                placeholder="请输入钱包编码"
                @blur="handleWalletcode"
              />
            </div>
          </div>
        </div>
        <template
          v-if="
            withdrawInfo.receiptMode == 1 ||
            withdrawInfo.receiptMode == 2 ||
            withdrawInfo.receiptMode == 6
          "
        >
          <div class="input-div">
            <label class="form-label">选择{{ bankText }}</label>
            <div class="input">
              <el-select
                v-if="
                  !isWap &&
                  (withdrawInfo.receiptMode == 1 ||
                    withdrawInfo.receiptMode == 2)
                "
                class="select-pc"
                v-model="withdrawInfo.bankId"
                :placeholder="'请选择' + bankText"
                @change="onCheckBank"
              >
                <template v-if="withdrawInfo.receiptMode == 1">
                  <el-option
                    v-for="item in bankList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </template>
                <template v-if="withdrawInfo.receiptMode == 2">
                  <el-option
                    v-for="item in addrList"
                    :key="item.val"
                    :label="item.currencyAddress"
                    :value="item.val"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-if="!isWap && withdrawInfo.receiptMode == 6"
                class="select-pc"
                v-model="withdrawInfo.bankname"
                :placeholder="'请选择' + bankText"
                @change="onCheckBank"
              >
                <el-option
                  v-for="(item, index) in bankNameList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div
                class="input-content select-wap"
                v-if="isWap"
                @click="selectBankOrAddr"
              >
                <input
                  v-model="currBankText"
                  type="text"
                  readonly
                  :placeholder="'请选择' + bankText"
                />
                <i class="arrow arrow-down"></i>
              </div>
            </div>
          </div>
          <!-- <template v-if="withdrawInfo.receiptMode == 2">
            <div class="warn">
              * 请确保您提供的地址正确，因收币地址错误而导致的财产损失，
              将由客户个人承担。
            </div>
            <button class="button" @click="goEdmitCode">收币地址管理</button>
          </template> -->
        </template>
        <template
          v-if="withdrawInfo.receiptMode == 3 || withdrawInfo.receiptMode == 4"
        >
          <div class="qrcode-div" v-if="withdrawInfo.receiptMode == 3">
            <div class="get-qrcode">
              <p>获取微信收款码方式：</p>
              <p>
                点击
                <span>微信</span>
                ><span>我</span>><span>支付</span>><span>收付款</span>><span>二维码收款</span>><span>设置金额</span>>
                <span>输入“换算人民币”展示金额</span
                >><span>点击确定</span>然后截图收款码
              </p>
            </div>
            <van-uploader
              class="uploader-qrcode"
              :after-read="onRead"
              :max-size="5242800"
              @oversize="overSize"
              upload-icon="plus"
              :style="
                withdrawInfo.qrCodeUrl
                  ? `backgroundImage: url(${withdrawInfo.qrCodeUrl})`
                  : ''
              "
            />
            <p class="p-center" v-if="!editId">
              您可以通过上传微信收款二维码来提现
            </p>
          </div>
          <div class="qrcode-div" v-if="withdrawInfo.receiptMode == 4">
            <div class="get-qrcode">
              <p>获取支付宝收款码方式：</p>
              <p>
                点击
                <span>支付宝</span>
                ><span>收付款</span>><span>二维码收款</span>><span>设置金额</span>><span
                  >输入“换 算人民币”展示金额</span
                >><span>点击确定</span>然后截图收款码
              </p>
            </div>
            <van-uploader
              class="uploader-qrcode"
              :after-read="onRead"
              :max-size="5242800"
              @oversize="overSize"
              upload-icon="plus"
              :style="
                withdrawInfo.qrCodeUrl
                  ? `backgroundImage: url(${withdrawInfo.qrCodeUrl})`
                  : ''
              "
            />
            <p class="p-center" v-if="!editId">
              您可以通过上传支付宝收款二维码来提现
            </p>
          </div>
          <div class="warn" v-if="!editId">
            温馨提示：收款二维码需要附带金额，不然可能无法通过申请哦！
          </div>
        </template>
        <div class="reason" v-if="editId">
          <p class="status">审核未通过</p>
          <p class="reason-info" v-if="remarks">原因：{{ remarks }}</p>
        </div>
        <button
          class="button"
          :disabled="subDisabled"
          v-checkSubmit="'form'"
          :submit="withdrawSubmit"
        >
          提交
        </button>
      </div>

      <div class="withdraw-tips">
        <div class="tips-title">温馨提示:</div>
        <p class="tips-p">1、出金金额小于50美金，需要扣除3美元手续费；</p>
        <p class="tips-p">
          2、当总交易金额不足总入金金额的{{
            mt4ChargePoint
          }}%，则需要扣除本次出金金额的{{ mt4Charge }}%做为手续费；
        </p>
        <p class="tips-p">3、当前有正在出金中的订单时，无法出金；</p>
        <p class="tips-p">4、当日MT4成功入金，则无法出金；</p>
        <p class="tips-p">
          5、出金申请的处理时间是工作日的9:00~18:00，不在此时间的出金申请，工作人员会在最近的一个工作日进行处理，请耐心等待；
        </p>
        <p class="tips-p">
          6、我司承诺24小时之内完成审核并汇出款项，实际到账时间视银行作业时间而定；
        </p>
      </div>
    </div>
    <van-popup v-if="isWap" v-model="showReceiptMode" position="bottom">
      <van-picker
        :loading="receiptModeLoading"
        ref="receiptModeList"
        show-toolbar
        :columns="receiptModeList"
        value-key="name"
        @cancel="showReceiptMode = false"
        @confirm="onCheckReceiptMode"
      />
    </van-popup>
    <van-popup v-if="isWap" v-model="showBankList" position="bottom">
      <van-picker
        ref="bankList"
        v-if="withdrawInfo.receiptMode == 1"
        show-toolbar
        :columns="bankList"
        value-key="name"
        @cancel="showBankList = false"
        @confirm="onCheckBank"
      />
      <van-picker
        ref="codeList"
        v-if="withdrawInfo.receiptMode == 2"
        show-toolbar
        :columns="addrList"
        value-key="currencyAddress"
        @cancel="showBankList = false"
        @confirm="onCheckBank"
      />
      <van-picker
        ref="banknameList"
        v-if="withdrawInfo.receiptMode == 6"
        show-toolbar
        :columns="bankNameList"
        @cancel="showBankList = false"
        @confirm="onCheckBank"
      />
    </van-popup>
    <!-- 出金手续费确认 -->
    <Dialog
      ref="poundage"
      :dialogObj="dialogObj1"
      @confirm="handlePoundageConfirm"
      @cancel="handlePoundageCancel"
    />
    <!-- 出金成功提示 取款时间提示 -->
    <Dialog
      ref="succTime"
      :dialogObj="dialogObj3"
      @confirm="handleSuccTimeConfirm"
    />
    <!-- 出金成功提示 -->
    <Dialog
      ref="succ"
      :dialogObj="dialogObj2"
      @confirm="handleSuccConfirm"
      @cancel="handleSuccCancel"
    >
      <div class="dialog-detail">
        <p class="dialog-p">
          <span class="p-title">订单号</span>
          <span class="p-val">{{ succInfo.goldNo }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">提现金额</span>
          <span class="p-val">{{ succInfo.money }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">{{
            withdrawInfo.receiptMode == 2 ? "美元/USDT汇率" : "美元/人民币汇率"
          }}</span>
          <span class="p-val">{{
            withdrawInfo.receiptMode == 2
              ? "1美元≈" + succInfo.dollerUsdtRate + "USDT"
              : "1美元≈" + succInfo.dollerRmbRate + "人民币"
          }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">提交时间</span>
          <span class="p-val">{{ succInfo.createTime }}</span>
        </p>
        <p class="dialog-p mt-12">
          <span class="p-title">状态</span>
          <span class="p-val">审核中</span>
        </p>
      </div>
      <div class="dialog-tips">
        <p class="tips-title">温馨提示</p>
        <p class="tips-p">
          工作人员会在第一时间处理您的提现申请，成功后会以APP消息推送方式通知您,详情可在“资金明细”中查看。
        </p>
      </div>
      <!-- 出金详细信息 -->
    </Dialog>
    <van-popup
      v-model="showNextPopup"
      :close-on-click-overlay="false"
      :style="{ width: '500px' }"
    >
      <div class="showNextPopupBox">
        <div class="close" @click="nextActive">×</div>
        <div class="title">温馨提示</div>
        <div class="text">
          您现在正在参与【交易现金】活动，交易手数未达标，如果您选择【继续出金】会扣回您的交易现金，您选择
        </div>
        <div class="btnBox">
          <div class="left" @click="nextPopup">继续出金</div>
          <div class="right" @click="nextActive">继续活动</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import funds from "@/apis/funds.js";
import account from "@/apis/account.js";
import { oss } from "@/mixins/ossUpload.js";
import Dialog from "@/components/dialog.vue";
export default {
  name: "Withdraw",
  mixins: [oss],
  components: {
    Dialog,
  },
  data() {
    const maneyVerify = (value, callback) => {
      if (value === "") {
        callback("金额不能为空");
      } else if (+value <= 3) {
        callback("最小金额需>$3");
      } else if (+value > +this.balance) {
        callback(`最大金额为${this.balance}`);
      } else if (
        !/(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/g.test(+value)
      ) {
        callback("请输入正确金额");
      }
    };
    return {
      vfy1: false, //是否绑定身份证
      vfy2: false, //是否绑定卡
      isAuthMTMsg: "", //不可提现时的信息
      isAuthentication: false, //是否可以提现
      balance: "", //可提现余额信息
      mt4ChargePoint: "50", //出金金额比例
      mt4Charge: "5", //出金金额手续费比例
      rules: {
        money: [maneyVerify],
        outRmb: [{ rule: "required", msg: "获取换算人民币失败" }],
        receiptMode: [{ rule: "required", msg: "请选择取款方式" }],
      },
      withdrawInfo: {
        mjMoney: "", //提现金额
        bankId: "", //提现银行卡id或币id
        receiptMode: "", //取款方式id
        qrCodeUrl: "", //取款二维码
        walletcode: "", // 钱包编码
        bankname: "", // 银行名称(数字人民币使用)
      },
      oldMjMoney: "", //最近一次修改前的出金金额
      editMjMoney: "", //修改的订单出金金额
      editId: "", //修改的订单id
      editReceiptMode: "", //修改的取款方式id
      outRmb: "", //提现人民币
      receiptModeText: "", //取款方式名称
      showReceiptMode: false, //是否显示取款方式
      showBankList: false, //是否显示选择银行卡、地址
      receiptModeList: [], // {1: "银行卡", 3: "微信", 4: "支付宝", 5: "云闪付"}, //可用的取款方式列表
      bankList: [], //可用的银行卡列表
      addrList: [], //可用的币地址列表
      bankNameList: [], //可用的数字人民币 银行名称列表
      bankText: "银行卡", //默认银行卡  显示选择的对应取款方式
      subDisabled: false,
      currBankText: "", //当前选择的银行/币地址
      remarks: "", //未通过原因
      isCanGetReceiptMode: false, //编辑订单时 如果没有修改取款金额 不可修改取款方式
      subLoading: "", //提交订单的加载状态
      receiptModeLoading: false,
      dialogObj1: {}, //手续费确认弹窗
      dialogObj2: {}, //提交成功弹窗 显示成功信息
      dialogObj3: {}, //提交成功弹窗 显示取款时间信息
      succInfo: {},
      showNextPopup: false, //提交订单的选择 继续出金/继续活动 弹窗
    };
  },
  computed: {
    isWap() {
      return this.$store.state.common.isWap;
    },
  },
  methods: {
    // 跳转绑定
    goBind(flag) {
      if (flag == 1) {
        this.$router.push({
          name: "account",
        });
      } else if (flag == 2) {
        this.$router.push({
          name: "bank",
        });
      }
    },
    //点击查看详情
    handleGoInfo() {
      if (this.isAuthMTMsg.indexOf("激活") > -1) {
        //未激活，跳转充值
        this.$router.push({
          name: "deposit",
        });
        return;
      }
      if (this.isAuthMTMsg.indexOf("存款成功") > -1) {
        //存款成功未超过24小时  跳转资金详情-入金
        this.$router.push({
          name: "fundsDetails",
        });
        return;
      }
      if (this.isAuthMTMsg.indexOf("正在出金中") > -1) {
        //当前有正在出金中的订单时  跳转资金详情-出金
        this.$router.push({
          name: "fundsDetails",
          query: {
            source: 2,
          },
        });
        return;
      }
    },
    //设置图片 此处type不使用 只有一处图片不区分给哪个设置
    setImgUrl(url) {
      // document.getElementsByClassName(
      //   "uploader-qrcode"
      // )[0].style.backgroundImage = `url(${url})`;
      this.withdrawInfo.qrCodeUrl = url;
    },
    // 上传图片
    async onRead(file) {
      //this.doUpload(file.file, "");
      this.$toast({
        type: "loading",
        mask: true,
        message: "上传中...",
        duration: 0,
      });
      try {
        var formData = new FormData();
        formData.append("rootpath", "gold");
        formData.append("file", file.file);
        funds.upload2sftp(formData).then((res) => {
          if (res.data.IsSuccess) {
            this.setImgUrl(res.data.Data);
            this.$toast.clear();
          } else {
            this.$toast.clear();
            this.$toast("上传失败");
          }
        });
      } catch (error) {
        this.$toast.clear();
        this.$toast("上传失败");
      }
    },
    // 编辑币地址
    goEdmitCode() {
      this.$router.push({
        name: "coinAddr",
        query: {
          source: this.$route.query.source,
        },
      });
    },
    checkMoney() {
      if (this.withdrawInfo.mjMoney === "") {
        this.$toast("金额不能为空");
        return false;
      } else if (+this.withdrawInfo.mjMoney <= 3) {
        this.$toast("最小金额需>$3");
        return false;
      } else if (+this.withdrawInfo.mjMoney > +this.balance) {
        this.$toast(`最大金额为${this.balance}`);
        return false;
      } else if (
        !/(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/g.test(
          +this.withdrawInfo.mjMoney
        )
      ) {
        this.$toast("请输入正确金额");
        return false;
      } else {
        return true;
      }
    },
    //输入金额后
    handleMoney() {
      if (!this.checkMoney()) {
        return;
      }
      //编辑订单时未修改金额不可修改取款方式
      if (this.editId && this.editMjMoney == this.withdrawInfo.mjMoney) {
        return;
      } else {
        if (this.editId) {
          this.isCanGetReceiptMode = true;
          this.editId = "";
          this.withdrawInfo.receiptMode = "";
          this.receiptModeText = "";
          this.receiptModeList = [];
          this.oldMjMoney = this.withdrawInfo.mjMoney;
        } else {
          if (this.oldMjMoney == this.withdrawInfo.mjMoney) {
            //虽然从金额处移开 但是未修改金额 不获取出金方式
            return;
          } else {
            this.oldMjMoney = this.withdrawInfo.mjMoney; //金额修改后获取出金方式 并将之前的出金方式置空
            this.withdrawInfo.receiptMode = "";
            this.receiptModeText = "";
            this.receiptModeList = [];
          }
        }
      }
      //获取提现方式等信息
      this.getWithdrawMode();
    },
    // 确认手续费
    withdrawSubmit() {
      if (!this.checkMoney() || this.subDisabled) {
        return;
      }
      if (!this.withdrawInfo.receiptMode) {
        this.$toast("请先选择取款方式");
        return;
      }
      if (this.withdrawInfo.receiptMode == 1 && !this.withdrawInfo.bankId) {
        this.$toast("获取银行卡信息失败，请刷新页面重试");
        return;
      }
      if (this.withdrawInfo.receiptMode == 2 && !this.withdrawInfo.bankId) {
        this.$toast("获取币地址信息失败，请刷新页面重试");
        return;
      }
      if (
        this.withdrawInfo.receiptMode == 3 ||
        this.withdrawInfo.receiptMode == 4
      ) {
        if (!this.withdrawInfo.qrCodeUrl) {
          this.$toast("请先上传收款码");
          return;
        }
      }
      if (this.withdrawInfo.receiptMode == 6) {
        if (!this.withdrawInfo.walletcode || !this.withdrawInfo.bankname) {
          this.$toast("获取钱包编码或银行名称失败，请刷新页面重试");
          return;
        }
        if (!this.handleWalletcode()) {
          return;
        }
      }
      this.subDisabled = true;
      this.subLoading = this.$toast.loading({
        duration: 0,
        overlay: true,
        message: "提交中...",
      });
      this.nextStep();
    },
    //确认手续费后
    nextStep() {
      let params = {
        mjMoney: this.withdrawInfo.mjMoney,
      };
      funds.poundageConfirm(params).then((res) => {
        if (!res.IsSuccess) {
          if (
            res.Data.indexOf("余额不足") > -1 ||
            res.Data.indexOf("余额超时") > -1
          ) {
            this.subLoading.clear();
            this.subDisabled = false;
            this.$toast(res.Data);
            return;
          }
          this.dialogObj1 = {
            isShow: true,
            title: "请确认",
            content: res.Data,
            confirmText: "确认",
            cancelText: "取消",
          };
          this.subLoading.clear();
        } else {
          this.goWithdraw();
        }
      });
    },
    // 弹窗 继续活动
    nextActive() {
      this.showNextPopup = false;
      this.subLoading.clear();
      this.subDisabled = false;
    },
    // 弹窗 继续出金
    nextPopup() {
      funds.getCloseTradeCashActivity().then(() => {
        this.goWithdraw();
      });
    },
    handlePoundageConfirm() {
      this.goWithdraw();
    },
    handlePoundageCancel() {
      this.subLoading.clear();
      this.subDisabled = false;
    },
    //  确认提现
    goWithdraw() {
      this.subLoading = this.$toast.loading({
        duration: 0,
        overlay: true,
        message: "提交中...",
      });
      let params = this.withdrawInfo;
      if (this.editId) {
        if (
          this.editMjMoney == this.withdrawInfo.mjMoney ||
          this.editReceiptMode == this.withdrawInfo.receiptMode
        ) {
          //取款金额或取款方式改变，提交新订单，否则修改订单
          params = Object.assign(this.withdrawInfo, { id: this.editId });
        }
      }
      funds.withdraw(params).then((res) => {
        if (res.IsSuccess) {
          if (res.Data == "activityGoing") {
            this.showNextPopup = true;
            return;
          }
          this.showNextPopup = false;
          this.succInfo = res.Data;
          // this.dialogObj2 = {
          //   isShow: true,
          //   showSuccImg: true,
          //   content: "提交成功",
          //   subContent: "请耐心等待", //次级内容
          //   confirmText: "返回首页",
          //   cancelText: "资金明细",
          // };
          this.dialogObj3 = {
            isShow: true,
            content:
              "由于出金人数较多，您的资金最迟会在明日12:00前到账，如有需要请联系客服跟进",
            confirmText: "确定",
            cancelText: "",
            isClose: false,
          };
          this.subLoading.clear();
        } else {
          this.subLoading.clear();
          this.subDisabled = false;
          this.$toast("出金失败，请重试");
        }
      });
    },
    handleSuccTimeConfirm() {
      this.dialogObj2 = {
        isShow: true,
        showSuccImg: true,
        content: "提交成功",
        subContent: "请耐心等待", //次级内容
        confirmText: "返回首页",
        cancelText: "资金明细",
      };
    },
    handleSuccConfirm() {
      this.$router.push({
        name: "index",
      });
    },
    handleSuccCancel() {
      this.$router.push({
        name: "fundsDetails",
        query: {
          source: 2,
        },
      });
    },
    // 获取支持提现的币地址
    getCodeAddrList() {
      funds
        .getBindCodeList()
        .then((res) => {
          if (res.IsSuccess) {
            this.$nextTick(() => {
              this.addrList = res.Data.sort((a, b) => {
                return b.status - 0 - (a.status - 0);
              });
              //如果是编辑订单  选中之前的选择
              if (this.editId) {
                for (let i in this.addrList) {
                  if (this.withdrawInfo.bankId == this.addrList[i]["id"]) {
                    this.currBankText = this.addrList[i]["currencyAddress"];
                  }
                }
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取支持提现的银行
    getChoiceBankList() {
      funds.getChoiceBankList().then((res) => {
        if (res.IsSuccess) {
          this.bankList = res.Data;
          //如果是编辑订单  选中之前的选择
          if (this.editId) {
            let data = res.Data;
            for (let i in data) {
              if (this.withdrawInfo.bankId == data[i]["id"]) {
                this.currBankText = data[i]["name"];
              }
            }
          }
        }
      });
    },
    // 获取提现余额
    getBalanceInfo() {
      account.getAccoutMarginFree().then((res) => {
        if (res.IsSuccess) {
          if (res.Data.message) {
            this.$toast(res.Data.message);
          } else {
            this.balance = res.Data.accMarginFree.balance;
          }
        }
      });
    },
    // 获取出金时手续费比例
    getServiceCharge() {
      funds.getServiceCharge().then((res) => {
        if (res.IsSuccess) {
          this.mt4ChargePoint = res.Data.mt4ChargePoint;
          this.mt4Charge = res.Data.mt4Charge;
        }
      });
    },
    // 检测是否绑定身份与银行卡
    identityInfo() {
      funds.getIdentityInfo().then((res) => {
        if (res.IsSuccess) {
          if (res.Data.userBank === false || res.Data.userCard === false) {
            if (!res.Data.userBank) {
              // 需绑定卡
              this.vfy2 = true;
            }
            if (!res.Data.userCard) {
              // 需绑定身份
              this.vfy1 = true;
            }
            this.isAuthentication = false;
            return;
          }
          this.isAuthentication = true;
          this.getBalanceInfo();
          this.getServiceCharge();
          if (res.Data.gold) {
            //出金审核失败  可编辑更新出金，当出金金额变化后改为重新提交新订单
            this.editWithdraw(res.Data.gold);
          }
        } else {
          this.isAuthMTMsg = res.Data;
        }
      });
    },
    //编辑出金信息
    editWithdraw(data) {
      this.withdrawInfo.mjMoney = data.mjMoney;
      this.withdrawInfo.bankId = data.bingbankId;
      this.withdrawInfo.receiptMode = data.receiptMode;
      this.withdrawInfo.qrCodeUrl = data.qrcodeUrl;
      this.outRmb = data.rmbActualRoundMoney;
      this.editId = data.id; //该订单id
      this.editMjMoney = data.mjMoney;
      this.editReceiptMode = data.receiptMode;
      this.remarks = data.remarks;
      this.receiptModeText = data.receiptModeName;
      this.receiptModeList.push({
        val: data.receiptMode,
        name: data.receiptModeName,
      });
      //获取出金方式 编辑时暂不获取
      //this._getWithdrawMode(data.mjMoney)
      //根据出金方式获取银行卡或币地址列表 并选中
      if (data.receiptMode == 1) {
        //银行卡出金  获取银行卡列表
        this.getChoiceBankList();
      }
      if (data.receiptMode == 2) {
        //数字货币出金  获取币地址列表
        this.getCodeAddrList();
      }
      if (data.receiptMode == 6) {
        //数字人民币出金
        this.withdrawInfo.walletcode = data.walletcode;
        this.withdrawInfo.bankname = data.bankname;
        this.currBankText = data.bankname;
        this.bankNameList.push(data.bankname);
      }
    },
    //获取提现方式等信息
    getWithdrawMode(money) {
      this.$toast.loading({
        duration: 0,
        mask: true,
        message: "加载取款方式中...",
      });
      this.receiptModeLoading = true;
      funds
        .getWithdrawMode({
          params: { mjMoney: money ? money : this.withdrawInfo.mjMoney },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.IsSuccess) {
            this.outRmb = res.Data.rmbActualRoundMoney;
            this.receiptModeList = [];
            for (let i in res.Data.receiptModeList) {
              if (this.editId && this.withdrawInfo.receiptMode == i) {
                this.receiptModeText = res.Data.receiptModeList[i];
              }
              if (res.Data.receiptModeList[i].indexOf("数字钱包") > -1) {
                //pc,h5不支持数字钱包
                continue;
              }
              this.receiptModeList.push({
                val: parseInt(i),
                name: res.Data.receiptModeList[i],
              });
            }
            //数字人民币取款时的可用银行赋值
            this.bankNameList = res.Data.banks;
            this.receiptModeLoading = false;
          } else {
            this.$toast(res.Data);
            this.receiptModeLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.clear();
          this.receiptModeLoading = false;
        });
    },
    //选择出金方式后
    onCheckReceiptMode(data) {
      //pc选择取款方式后
      if (typeof data == "number" && !this.isWap) {
        this.withdrawInfo.qrCodeUrl = "";
      } else {
        //wap选择取款方式后
        this.showReceiptMode = false;
        //receiptMode; //到账方式（1:银行卡,2:数字钱包,3:微信,4:支付宝,5:云闪付,6:数字人民币）
        //如果出金方式与上次不同 重置已选择的银行卡或币地址
        if (this.withdrawInfo.receiptMode != data.val) {
          this.currBankText = "";
          this.withdrawInfo.qrCodeUrl = "";
        }
        this.withdrawInfo.receiptMode = data.val;
        this.receiptModeText = data.name;
      }
      //银行卡取款  获取银行卡列表
      if (this.withdrawInfo.receiptMode == 1) {
        this.bankText = "银行卡";
        !this.bankList.length && this.getChoiceBankList();
      } else if (this.withdrawInfo.receiptMode == 2) {
        //如果数字货币取款 获取币地址列表
        this.bankText = "地址";
        !this.addrList.length && this.getCodeAddrList();
      } else if (this.withdrawInfo.receiptMode == 6) {
        //如果数字人民币取款 获取银行列表
        this.bankText = "银行";
        !this.bankNameList.length &&
          this.$toast("银行名称获取失败，请刷新页面或输入金额后重试");
      }
      //获取默认出金信息
      this.getApplyGoldInfo();
    },
    //获取默认出金信息
    getApplyGoldInfo() {
      this.$toast.loading({
        duration: 0,
        mask: true,
        message: "加载中...",
      });
      funds
        .getApplyGoldInfo({ receiptMode: this.withdrawInfo.receiptMode })
        .then((res) => {
          this.$toast.clear();
          if (res.IsSuccess) {
            //赋值默认银行卡信息
            this.withdrawInfo.bankId = res.Data.id;
            if (this.withdrawInfo.receiptMode != 6) {
              if (this.withdrawInfo.receiptMode == 2) {
                this.currBankText = res.Data.currencyAddress;
              } else {
                this.currBankText = res.Data.name;
              }
            }
          } else {
            this.$toast(res.Data);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.clear();
        });
    },
    // 选中银行/币地址
    onCheckBank(item) {
      //pc选择银行/币地址
      if (typeof item == "string" && !this.isWap) {
        if (this.withdrawInfo.receiptMode != 6) {
          this.withdrawInfo.bankId = item;
        } else {
          this.currBankText = item;
          this.withdrawInfo.bankname = item;
        }
      } else {
        //wap选择银行/币地址
        this.showBankList = false;
        if (this.withdrawInfo.receiptMode == 1) {
          this.withdrawInfo.bankId = item.id;
          this.currBankText = item.name;
        } else if (this.withdrawInfo.receiptMode == 2) {
          this.withdrawInfo.bankId = item.id;
          this.currBankText = item.currencyAddress;
        } else if (this.withdrawInfo.receiptMode == 6) {
          this.currBankText = item;
          this.withdrawInfo.bankname = item;
        }
      }
    },
    //选择银行卡或币地址
    selectBankOrAddr() {
      if (this.withdrawInfo.receiptMode == 1) {
        !this.bankList.length && this.getChoiceBankList();
      } else if (this.withdrawInfo.receiptMode == 2) {
        !this.addrList.length && this.getCodeAddrList();
      } else if (this.withdrawInfo.receiptMode == 6) {
        !this.bankNameList.length &&
          this.$toast("银行名称获取失败，请刷新页面或输入金额后重试");
      }
      this.showBankList = true;
    },
    //点击选择取款方式时
    handleShowReceiptMode() {
      if (this.editId && !this.isCanGetReceiptMode) {
        this.$toast("修改取款金额后，才可修改取款方式");
      } else {
        if (!this.withdrawInfo.mjMoney) {
          this.$toast("请先输入取款金额");
          return;
        }
        this.showReceiptMode = true;
      }
    },
    //验证钱包编码
    handleWalletcode() {
      if (
        this.withdrawInfo.walletcode.length < 4 ||
        this.withdrawInfo.walletcode.length > 20
      ) {
        this.$toast("钱包编码长度需4-20位");
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.identityInfo();
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/funds/withdraw.scss";
</style>
